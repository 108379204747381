.nav {
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;
  font-size: 18px;
  .nav-link {
    font-weight: 400;
    height: 100%;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    color: var(--color-neutral-0);
    cursor: pointer;
    text-transform: capitalize;
    white-space: nowrap;
    &:not([href]):not([tabindex]) {
      color: $text-black;
    }
    margin-right: 40px;
    border-bottom: 2px solid transparent;
    img,
    svg {
      height: 30px;
      margin-right: 10px;
      filter: greyscale(60%);
    }
    svg {
      font-size: 30px;
    }
    &.user-form {
      margin-top: 1px;
    }
    &.active,
    &:hover {
      border-bottom: 2px solid #1d212a;
      margin-top: 1px;
      z-index: 29;
      img,
      svg {
        filter: none;
      }
    }
  }
}

.nav-secondary {
  min-height: 46px;
  border-bottom: 1px solid $grey-400;
  margin-top: 20px;
  .nav-link {
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
    height: 46px;
    margin-right: 50px;
    padding-left: 15px;
    padding-right: 15px;
    color: var(--color-neutral-100);
  }
}

.tooltip-arrow {
  margin-left: 40px;
}

.fas.fa-circle.side-bar-alert-circle.side-bar-alert-circle {
  color: #ffcf01;
  font-size: 6px;
  left: 22px;
  top: 0px;
  position: absolute;
}

.burger {
  display: inline-block;
  cursor: pointer;

  align-self: center;
  left: 15px;
  //position: absolute;
  //z-index: 2005;

  height: 35px;
  margin: 20px 0 0 50px;

  &:hover {
    .bar1,
    .bar2,
    .bar3 {
      background-color: #fff;
    }
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 25px;
    height: 5px;
    background-color: $marengo-200;
    margin: 5px 0;
    transition: 0.2s;
    border-radius: 5px;
  }
  .change {
    &.bar1 {
      -webkit-transform: rotate(-45deg) translate(-6px, 6px);
      transform: rotate(-45deg) translate(-6px, 6px);
    }
    &.bar2 {
      opacity: 0;
    }
    &.bar3 {
      -webkit-transform: rotate(45deg) translate(-8px, -8px);
      transform: rotate(45deg) translate(-8px, -8px);
    }
  }
}

.company-chooser {
  @extend .noselect;
  width: auto;
  height: 3rem;
  margin: 2rem;
  border-radius: 2rem;
  &.is-open {
    .Select-control {
      border: 2px solid $marengo-500;
      background-color: $marengo-700;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
      .Select-arrow {
        border-color: transparent transparent white !important;
      }
    }
  }
  &.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
    border: 2px solid $marengo-500;
  }
  &.is-focused > .Select-control {
    background: inherit;
  }
  .Select-control {
    width: 100%;
    height: 3rem !important;
    //margin: 2rem auto;
    border-radius: 2rem;
    border: 2px solid $marengo-500;
    background-color: $marengo-700;
    .Select-value {
      line-height: 2.75rem !important;
      height: 3rem !important;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      .Select-value-label {
        color: white !important;
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 600;
      }
    }
    .Select-input {
      &:focus {
        background: inherit;
      }
    }
    .Select-arrow-zone {
      .Select-arrow {
        border-color: white transparent transparent;
        margin-right: 1rem;
      }
    }
  }
  .Select-menu-outer {
    width: 100%;
    margin: auto;
    background-color: $marengo-700;
    left: 0;
    right: 0;
    border: 2px solid $marengo-500;
    border-top: none;
    border-radius: 0 0 2rem 2rem;
    padding: 1rem 1.25rem;
    .Select-menu {
      overflow-y: auto;
      background: transparent;
      max-height: 158px;
      /* Width scrollbar */
      &::-webkit-scrollbar {
        width: 0.25rem;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        border-radius: 0.25rem;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $marengo-500;
        border-radius: 0.25rem;
      }

      .Select-option {
        @extend .cut-long-text;
        background-color: inherit;
        color: $grey-400 !important;
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 600;
        padding: 0.6rem 0;
        &.is-focused {
          background-color: inherit;
          color: white !important;
        }
      }
    }
  }
}

.sidebar .icon-pin.pinned path,
.sidebar .icon-pin.pinned:hover path {
  fill: rgba(255, 255, 255, 1);
}

.sidebar .icon-pin:hover path {
  fill: rgba(225, 230, 244, 1);
}

.sidebar-footer-items {
  min-height: 205px;
}

.sidebar .sidebar-nav a.nav-link:hover .item {
  color: var(--color-neutral-0);
}

.sidebar .sidebar-nav a.nav-link.active .item {
  color: var(--color-neutral-0);
}

.sidebar .sidebar-nav a.nav-link:hover .item svg {
  color: currentColor;
}

.sidebar .sidebar-nav a.nav-link.active .item svg {
  color: currentColor;
}
