.sidebar {
  --sidebar-closed-width: 77px;
  --sidebar-opened-width: 256px;

  background-color: #f8f8f8;
  box-shadow: 0px 1px 4px 0px rgba(15, 19, 23, 0.24);
  min-width: var(--sidebar-closed-width);
  height: 100%;
  overflow-y: visible !important;
  transition: none !important;
  position: fixed !important;
  z-index: 2000 !important;
  width: min-content !important;
}

.sidebar.sidebar--opened {
  min-width: var(--sidebar-opened-width);
}

.sidebar-items {
  flex-grow: 2;
  overflow: overlay;
}

.kandji-sidebar {
  width: min-content !important;
}

.kandji-sidebar.kandji-sidebar--opened {
  min-width: var(--sidebar-opened-width) !important;
}

.kandji-sidebar--below-dialog {
  z-index: 998 !important;
}

.kandji-sidebar__overlay {
  visibility: hidden !important;
  background-color: transparent !important;
}

.kandji-sidebar__tooltip.show {
  opacity: 1;
  padding-left: 14px;
}

.kandji-sidebar__tooltip.--with-scrollbar {
  padding-left: 18px;
}

.kandji-sidebar__tooltip > .tooltip-inner {
  font-family: var(--font-family-primary);
  box-shadow: none;
  background-color: #1b1f29;
  color: #ffffff;
  font-size: 13px;
  padding: 5px 12px;
}

.kandji-sidebar__tooltip > .arrow {
  left: 10px;
}

.kandji-sidebar__tooltip.--with-scrollbar > .arrow {
  left: 14px;
}

.kandji-sidebar__tooltip > .arrow::before {
  border-right-color: #1b1f29;
}